import {websocketModels} from '../datamodels/webSocketModels'

export const updatewebSocketReducer = (state=websocketModels(),action) =>{

    if(action.type==="UPDATE_WEB_SOCKET"){
        return Object.assign({},state,action.payload);
    }
    if(action.type==="LOGOUT_USER"){
        return websocketModels();
    }
    return state;
};

const reducer = {
    webSocketData:updatewebSocketReducer
};
export default reducer;
