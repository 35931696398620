import React,{useEffect} from 'react';
import useWindowsDimensions from "src/components/supporting/UseWindowsDimensions";
import store from 'src/store';
import {updateAppSettings} from "src/actions/foundationActions";


const MediaQueryTool = () => {
    let windowDim = useWindowsDimensions();
    useEffect(()=>{
        let xs = document.querySelector("#xsExist");
        let small = document.querySelector("#smExist");
        let med = document.querySelector("#mdExist");
        let large = document.querySelector("#lgExist");
        let extraL = document.querySelector("#xlExist");
        let breakpoint = (
            xs.getBoundingClientRect().width
                ? "xs"
                : small.getBoundingClientRect().width
                    ? "sm"
                    : med.getBoundingClientRect().width
                        ? "md"
                        : large.getBoundingClientRect().width
                            ? "lg"
                            : extraL.getBoundingClientRect().width
                                ? "xl"
                                : "confused");
        store.dispatch(updateAppSettings({breakpoint:breakpoint}));
    },[windowDim.height,windowDim.width]);

    return (
        <div id="mqWrapper">
            <span id="xsExist" className={"d-block d-sm-none "} />
            <span id="smExist" className={"d-none  d-sm-block d-md-none "} />
            <span id="mdExist" className={"d-none  d-md-block d-lg-none"} />
            <span id="lgExist" className={"d-none  d-lg-block d-xl-none"} />
            <span id="xlExist" className={"d-none  d-xl-block"} />
        </div>
    )
};

export default MediaQueryTool;
