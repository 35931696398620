import axios from "axios";
import {apiURL,helpURL,randNum} from "../utilities/utilityFunctions";
import {appSettingModel} from "../datamodels/foundation";

export const decodeToken = (token, callBack, fail) => {
    axios.get(apiURL() + "/invitations/decode/" + token,{headers:{
            "DomainCode":appSettingModel().domainCode,
            "OffsetTime":(new Date()).getTimezoneOffset()
        }}).then((evt) => callBack(evt)).catch((evt) => fail(evt));
};

export const obtainContent = (slug,callBack,fail) => {
    axios.get(helpURL() + slug + ".json?" + randNum(1,99)).then((evt) => callBack(evt)).catch((evt) => fail(evt));
};
