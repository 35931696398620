import {obtainURLParam} from "./utilityFunctions";
import {decodeToken} from "../services/APIServices";
import store from 'src/store';
import {updateAppSettings} from "../actions/foundationActions";
import {updateTesting} from "../actions/testingActions";

export const URLTokenInterpreter = (props) =>{
    let token = obtainURLParam("gtt");
    if(token){
        decodeToken(token,processDecode,failDecode);
    }
    let testMode=obtainURLParam("testMode");
    if(testMode){
        store.dispatch(updateAppSettings({testMode:true}));
    }
    let randomLoad = obtainURLParam("randLoad");
    if(randomLoad){
        store.dispatch(updateAppSettings({welcomeModName:"components/Testing/RandomLoad"}));
        store.dispatch(updateTesting({"randomLoadModName":randomLoad}));
    }
};

const processDecode = (evt)=>{
    let tokenD = Object.assign({},evt.data.data,{token:obtainURLParam("gtt")});
    if(tokenD){
        store.dispatch(updateAppSettings({tokenData:tokenD}));
    }
};

const failDecode = (evt) => {
    console.dir(evt);
};
