import {combineReducers, compose,applyMiddleware} from 'redux';
import foundationReducers from './foundationReducers';

import Thunk from 'redux-thunk';
import permissionsReducers from './permissionsReducers';
import testingReducers from './testingReducers';
import gameTimeReducers from './gameTimeReducers';
import websocketReducers from './websocketReducers';
import {reducer as SWRTCReducer} from "@andyet/simplewebrtc";
import TDPReducers from './TDPReducers';
import whiteBoardReducers from './whiteBoardReducers';
import talkioReducers from './talkioReducers';
import gameTimeTeamReducers from './gameTimeTeamReducers';


const allReducers=Object.assign({},foundationReducers,permissionsReducers,testingReducers,gameTimeReducers,websocketReducers,{simplewebrtc:SWRTCReducer},TDPReducers,whiteBoardReducers,talkioReducers,gameTimeTeamReducers);
compose(applyMiddleware(Thunk));
export default combineReducers(allReducers);
