const testingReducer = (state={},action) => {
    if (action.type === 'UPDATE_RANDOM_LOAD') {
        return  Object.assign({}, state, action.payload);
    }
    if(action.type==="LOGOUT_USER"){
        return {};
    }
    return state;
};

let exp = {
    testing:testingReducer
};


export default exp;
