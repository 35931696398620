

const talkyioReducer = (state={},action)=>{
    if(action.type==="UPDATE_TALKYIO"){
        return Object.assign({},state,action.payload);
    }
    return state;
};


let exp = {
    talkyio:talkyioReducer
};

export default exp;
