export const websocketModels = (props) =>{
    return {
        "clientid":"",
        "subscriptions":{},
        "messages":{},
        "contacts":{}
    };
};

export const subscriptionModel=()=>{
    return {subscribed:-1}
};
