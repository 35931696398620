const tdpRdeducer = (state={},action) => {
    if (action.type === 'UPDATE_TDP') {
        return  Object.assign({}, state, action.payload);
    }
    return state;
};

let exp = {
    tdp:tdpRdeducer
};

export default exp;
