export const gameState = (state={},action) => {
    if(action.type==="UPDATE_GAME_STATE"){
        return Object.assign({},state,action.payload)
    }
    if(action.type==="UPDATE_GAME_STATE_TEAMS"){
        let teams = state.teams ? state.teams : {};
        teams[action.payload.teamid] = action.payload;
        return Object.assign({},state,{teams:teams});
    }

    if(action.type==="REPLACE_GAME_STATE_TEAMS"){
        return Object.assign({},state,{teams:action.payload});
    }

    if(action.type==="RESET_GAME_STATE"){
        return action.payload;
    }
    return state;

};

export const newTeamToAddReducer = (state=[],action)=>{
    switch (action.type){
        case "ADD_TEAM_TO_ACTIVITY":
            let newTeams = [...state];
            newTeams.push(action.payload);
            return newTeams;
        case "UPDATED_NEW_TEAM_LIST":
            return [...action.payload];
        //return newList;
        case "LOGOUT_USER":
            return [];
        default:
            return state;
    }
};

export const teamJoinRequest = (state={},action)=>{
    if(action.type==='SUBMIT_TEAM_FOR_APPROVAL'){
        return action.payload;
    }
    if(action.type==="REMOVE_SUBMIT_TEAM_FOR_APPROVAL"){
        return {};
    }
    if(action.type==="LOGOUT_USER"){
        return {};
    }
    return state;
};


const reducer = {
    teamsToAdd:newTeamToAddReducer,
    submitTeamForApproval:teamJoinRequest,
    gameState:gameState
};

export default reducer;
