export const defaultStructure = ()=>{
    return {
        //"color":"#000000",
        //"eraseColor":"#ffffff",
        //"drawing":false,
        //"tool":"mouse",
        "cursors":{}
    }
};

export const whiteBoardAction = () => {
    return {
        "color":"",
        "from":{},
        "to":{},
        "canvWidth":"",
        "canvHeight":"",
        "position":"",
        "id":""
    }
};
