import {defaultStructure} from 'src/datamodels/whiteBoardModels';

const whiteBoardReducer = (state=defaultStructure(),action) => {
    if(action.type==="UPDATE_WHITEBOARD_SETTING"){
        return Object.assign({},state,action.payload);
    }

    if(action.type==="UPDATE_CURSOR"){
        let allpositions = state.cursors;
        allpositions[action.payload.id]=Object.assign({},allpositions[action.payload.id],action.payload);
        return Object.assign({},state,{cursors:allpositions});
    }
    return state;
};

const whiteBoardActionsReducer = (state=[],action)=>{
    if(action.type==="ADD_WHITEBOARD_ACTION"){
        let newState= [...state];
        newState.push(action.payload);
        return newState;
    }

    if(action.type==='CLEAR_WHITEBOARD'){
        return []
    }

    if(action.type==='REPLACE_WHITEBOARD'){
        return action.payload
    }


    return state;
};

let exp = {
    whiteboardData:whiteBoardReducer,
    whiteBoardAction:whiteBoardActionsReducer
};

export default exp
