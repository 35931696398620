import React,{useEffect} from 'react';
import {routes} from "../datamodels/routes";
import {makeAppTopMod} from "./utilityFunctions";


const URLParser = () => {
    useEffect(()=>{
        if(routes.hasOwnProperty(window.location.pathname.toLowerCase())){
            makeAppTopMod(routes[window.location.pathname.toLowerCase()].component);
        }
    },[]);
    return <div style={{display:"none"}}>Parser</div>
};

export default URLParser;
