import {appSettingModel} from "../datamodels/foundation";
import {createDictionary} from "../utilities/utilityFunctions";

const appSettingsReducer = (state=appSettingModel(),action) => {
    if (action.type === 'UPDATE_APP_SETTING') {
        return  Object.assign({}, state, action.payload);
    }
    if(action.type==="LOGOUT_USER"){
        return Object.assign({},appSettingModel(),{breakpoint:state.breakpoint});
    }
    return state;
};

const dataStoreReducer = (state = {},action)=>{
    if(action.type==='UPDATE_DATA_STORE'){
        return Object.assign({},state,action.payload);
    }
    if(action.type==="ADD_CONTROLS"){
        let allControls = Object.assign({},state.mediaControls,action.payload);
        return Object.assign({},state,{mediaControls:allControls});

    }
    if(action.type==="LOGOUT_USER"){
        return Object.assign({},{loadingModule:state.loadingModule,welcomeModule:state.welcomeModule,appMainModule:state.appMainModule});
    }
    return state;
};

const tabExists = (tabGroup,item) =>{
    let tabFilter = tabGroup.filter((tab)=>{
        return tab?.key===item.payload.key;
    });
    return tabFilter.length;
};

const tabsReducer = (state={dictionary:{}},action)=>{
    if(action.type==="ADD_TAB_TO_GROUP"){
        let newState = (state.hasOwnProperty(action.payload.group)
            ? Object.assign({},state)
            : Object.assign({},state,{[action.payload.group]:[]})
        );
        if(!tabExists(newState[action.payload.group],action)){newState[action.payload.group].push(action.payload)}
        newState.dictionary[action.payload.group]=createDictionary(newState[action.payload.group],"key");
        return newState;
    }

    if(action.type==="REMOVE_TAB_GROUP"){
        console.dir("remove_tab_group");
        let newState = Object.assign({},state);
        console.dir(newState);
        console.dir(action.payload);
        delete newState[action.payload];
        newState.dictionary[action.payload]=createDictionary(newState[action.payload],"key");
        console.dir(newState);
        return newState;
    }

    if(action.type==="REPLACE_TAB_GROUP"){

    }

    if(action.type==="TOGGLE_TAB"){
        let tabGroup = state.hasOwnProperty(action.payload.group) ? [...state[action.payload.group]] : [];
        //eslint-disable-next-line
        tabGroup.map((item,idx)=>{
            if(item["key"]===action.payload.key){
                tabGroup[idx].tabClass = "active";
                tabGroup[idx].panelVisible=true;
            }
            else{
                tabGroup[idx].tabClass = "";
                tabGroup[idx].panelVisible=false;
            }
        });

        let newDict = createDictionary(tabGroup,"key");
        let newState = Object.assign({},state);
        newState.dictionary[action.payload.group] = newDict;
        return newState;
    }

    return state
};

const tabGroupsReducer = (state={},action)=>{
    if(action.type==="ADD_TAB_GROUP"){
        return Object.assign({},state,{[action.payload.name]:action.payload});
    }
    return state;
};

const controlsReducer = (state={},action)=>{
    if(action.type==="ADD_CONTROL_TO_GROUP"){

    }

    if(action.type==="REPLACE_CONTROL_GROUP"){

    }

    return state
};


let exp = {
    appSettings:appSettingsReducer,
    dataStore:dataStoreReducer,
    tabStore:tabsReducer,
    controlStore:controlsReducer,
    tabGroups:tabGroupsReducer
};

export default exp;
