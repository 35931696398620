import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import store from './store'
//import 'react-sortable-tree/style.css';
import * as Sentry from '@sentry/react';
//import { Integrations } from "@sentry/tracing";
import {appSettingModel} from "./datamodels/foundation";

let senDsn = appSettingModel()["sentryDSN"];
if(senDsn) {
    Sentry.init({dsn: senDsn});
}

let localSites = appSettingModel()["localHosts"];
const correctLocation = () =>{
    //eslint-disable-next-line
    if(location.protocol.substr(0,5)!=='https' && localSites.indexOf(location.hostname) ===-1){
        //eslint-disable-next-line
        location.href="https://" + location.hostname + ':' + location.port;
    }
};

correctLocation();
    ReactDOM.render(
        <Provider store={store}>
            <DndProvider backend={Backend}>
                <App/>
            </DndProvider>
        </Provider>, document.getElementById('root'));
