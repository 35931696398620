import React from 'react';
import HeartFill from "src/components/icons/HeartFill";
import HeartHalf from "src/components/icons/HeartHalf";
import Heart from "src/components/icons/Heart";

export const appSettingModel = () => {
    return {
        apiURL:{
			"localhost":"https://api.gametime.local/api/v1",
            "runmytests.local":"https://api.gametime.local/api/v1",
            "herearetests.local":"https://api.gametime.local/api/v1",
            "app.toddlerdanceparty.com":"https://api.seeandplaymode.com/api/v1",
            "www.toddlerdanceparty.com":"https://api.seeandplaymode.com/api/v1",
            "toddlerdanceparty.com":"https://api.seeandplaymode.com/api/v1",
            "dev.toddlerdanceparty.com":"https://api.seeandplaymode.com/api/v1",
            "10.0.0.94":"https://api.seeandplaymode.com/api/v1"
        },
        storageURL:{
        },
		"helpURL":{            
			"localhost":"https://helpcontent.seeandplaymode.com/",
            "app.toddlerdanceparty.com":"https://helpcontent.seeandplaymode.com/",
            "www.toddlerdanceparty.com":"https://helpcontent.seeandplaymode.com/",
            "toddlerdanceparty.com":"https://helpcontent.seeandplaymode.com/",
            "dev.toddlerdanceparty.com":"https://helpcontent.seeandplaymode.com/",
            "10.0.0.94":"https://helpcontent.seeandplaymode.com/"
        },
		conversionURL:{
            "localhost":"https://ws2.seeandplaymode.com/api/v1/",
            "runmytests.local":"https://ws2.seeandplaymode.com/api/v1/",
            "herearetests.local":"https://aws2.seeandplaymode.com/api/v1/",
            "app.toddlerdanceparty.com":"https://ws2.seeandplaymode.com/api/v1/",
            "www.toddlerdanceparty.com":"https://ws2.seeandplaymode.com/api/v1/",
            "toddlerdanceparty.com":"https://ws2.seeandplaymode.com/api/v1/",
            "dev.toddlerdanceparty.com":"https://ws2.seeandplaymode.com/api/v1/",
            "10.0.0.94":"https://ws2.seeandplaymode.com/api/v1/"
        },
        wsURL:{
			"localhost":{domain:"ws2.seeandplaymode.com",secure:true,port:8553},
            "app.toddlerdanceparty.com":{domain:"ws2.seeandplaymode.com",secure:true,port:8553},
            "www.toddlerdanceparty.com":{domain:"ws2.seeandplaymode.com",secure:true,port:8553},
            "toddlerdanceparty.com":{domain:"ws2.seeandplaymode.com",secure:true,port:8553},
            "dev.toddlerdanceparty.com":{domain:"ws2.seeandplaymode.com",secure:true,port:8553},
            "10.0.0.94":{domain:"ws2.seeandplaymode.com",secure:true,port:8553}
        },
        contentURL:{
			"localhost":"https://d143ebdtyzz429.cloudfront.net",
            "runmytests.local":"https://d143ebdtyzz429.cloudfront.net",
            "herearetests.local":"https://d143ebdtyzz429.cloudfront.net",
            "api.gametime.local":"https://d143ebdtyzz429.cloudfront.net",
            "app.toddlerdanceparty.com":"https://d143ebdtyzz429.cloudfront.net",
            "www.toddlerdanceparty.com":"https://d143ebdtyzz429.cloudfront.net",
            "toddlerdanceparty.com":"https://d143ebdtyzz429.cloudfront.net",
            "dev.toddlerdanceparty.com":"https://d143ebdtyzz429.cloudfront.net",
            "10.0.0.94":"https://d143ebdtyzz429.cloudfront.net"
        },
        URLStruct:{
        },
		"welcomeModName":"components/TDP/TDPWelcome",
        "welcomeModule":null,
        "showSpinner":false,
        "locale":"en",
        "defaultModule": "components/TDP/TDPHome",
        "defaultLoggedInModule": "components/TDP/TDPLoggedIn",
        "appMainModule":null,
		"appMainModuleName":null,
		"logoSrc":null,
        "slogan":"Dance Yourself Smarter!",
        "localHosts":[
            "localhost",
            "127.0.0.1",
            "herearetests.local",
            "runmytests.local",
            "visualizationsite.local",
            "10.0.0.94"
        ],
        "modalModName":"components/TDP/Modal/TDPModal",
        "modalSideWrapper":null,
        "modalTopCloseButton":null,
        "loginFormName":"components/TDP/LoginFormTDP",
        "sentryDSN":"https://5e93c056330547308395f960721c95ad@o425228.ingest.sentry.io/5529552",
        "domainCode":"tdp",
        "roomModule":"components/TDP/TDPRoom/TDPRoom",
        "loadingModuleName":"components/TDP/UI/SpinnerHexHuge",
        "modulebackground":"",
        "activityHeaderColor":""
    }
};
/*
export const tabModel = (detail)=>{
    return {
        label: detail.label,
        group: detail.group,
        key: detail.key,
        tabVisible: detail.tabVisible,
        panelVisible:detail.panelVisible,
        tabStyle:detail.tabStyle
    }
};
*/
export const createTabModel = (label,group,key,tabVisible,panelVisible,tabStyle)=>{
    return {
        label: label,
        group: group,
        key: key,
        tabVisible: tabVisible,
        panelVisible:panelVisible,
        tabStyle:tabStyle
    }
};

export const createTabGroupObject = (name="",tabClassName="",tabStyle="",panelClassName="",panelStyle="") => {
    return {
        name:name,
        tabClassName:tabClassName,
        tabStyle:tabStyle,
        panelClassName:panelClassName,
        panelStyle:panelStyle
    }
};


export const blurbs = [
    {slug:"tdp-backstory",key:"backstory"},
    {slug:"tdp-about-us",key:"aboutus"},
   //{slug:"tdp-about-craft-table", key:"aboutCraftTable"},
    //{slug:"tdp-about-dance-party",key:"aboutDanceParty"},
    {slug:"tdp-activity-dance-party",key:"whatisdanceparty"},
    {slug:"tdp-about-newsletter", key:"aboutnewsletter"},
    {slug:"tdp-how-does-it-work",key:"howdoesitwork"},
    {slug:"tdp-why-kids-names",key:"whykidsnames"},
    {slug:"game-make-me-laugh",key:"makemelaugh"}
];

export const ratingIcons = {
    "1":<HeartFill />,
    "0":<HeartHalf/>,
    "-1":<Heart/>
};
/*
export const tabGroupModel = () => {
    return {
        name: "name of the tab group",
        tabClassName: "string: the className for the tabs",
        tabStyle: "object: any specific styles for this group",
        panelClassName: "String: the classNames for the panels",
        panelStyle: "Object: specific styles for the panel"
    }
}

 */
