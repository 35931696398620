import store from 'src/store'
import {updateDataStore} from "../actions/foundationActions";
import {updateAppSettings} from "../actions/foundationActions";

export const apiURL = (...args) => {
    //eslint-disable-next-line
    let site = args.length ? args[0] : self.location.hostname;
    let stateData=store.getState();

    return stateData?.appSettings?.apiURL?.hasOwnProperty(site) ? stateData?.appSettings.apiURL[site] : '';
};

export const closeModal = (triggerName="showDefaultModal") => {
    store.dispatch(updateAppSettings({[triggerName]:false,modalFooterText:"",modalTitle:null,modalModName:null}));
};

export const contentURL = (...args) => {
    let stateData=store.getState();
    //eslint-disable-next-line
    let site = args.length ? args[0] : self.location.hostname;

    return stateData?.appSettings?.contentURL?.hasOwnProperty(site) ? stateData?.appSettings.contentURL[site] : '';
};

export const createDictionary = (data, key,key2) => {
    let typeDict = {};
    //eslint-disable-next-line
    data?.map((item) => {
        typeDict[item[key]] = item;
        if(key2){typeDict[item[key2]]=item;}
    });
    return typeDict;
};

export const gridMaker = (orig) => {

    if(orig) {
        return sizeArray[orig-1];
        /*let rows = countRows(orig);
        let lastrownum = orig % rows;

        return {
            rows: rows,
            lastrownum: lastrownum,
            "or":rows % orig,
            numberacross: rows,
            total:orig
        };*/
    }
    /*
              let retme={
                columnGrid:[12],
                rows: countRows(orig)
            };

            if(orig===2){
                retme.columnGrid=[12,12];
                retme.rows=2;
            }
            else {
                let sqrt = Math.sqrt(orig);
                let numRows = Number.isInteger(sqrt) ? sqrt : Math.ceil(sqrt);
                let lastCols = orig % numRows;
                retme={
                    columnGrid:[],
                    rows: numRows
                };
                for (let i = 0; i < (orig - lastCols); i++) {
                    retme.columnGrid.push(Math.floor(100 / numRows));
                }
                for (let j = 0; j < lastCols; j++) {
                    retme.columnGrid.push(Math.floor(100 / lastCols));
                }
                /*
                for (let i = 0; i < (orig - lastCols); i++) {
                    retme.columnGrid.push(Math.floor(12 / numRows));
                }
                for (let j = 0; j < lastCols; j++) {
                    retme.columnGrid.push(Math.floor(12 / lastCols));
                }*/
    //}
    //  return retme;
    //};
    return {
        rows:1,
        lastrownum:1,
        numberacross:1
    };
};
/*
export const gridMakerPercent =(numItems) => {
    if(!numItems){
        return {}
    }
};
*/
export const gridMakerTable = (numItems) => {
    let rows = Math.floor( Math.sqrt(numItems) );
    return {
        rows:rows,
        cols:Math.floor(  Math.ceil( numItems / rows ) )
    }
};

export const helpURL = (...args) => {
    let stateData=store.getState();
    //eslint-disable-next-line
    let site = args.length ? args[0] : self.location.hostname;
    return stateData?.appSettings?.helpURL?.hasOwnProperty(site) ? stateData?.appSettings.helpURL[site] : '';
};

export const hideSpinner = () =>{
    store.dispatch(updateAppSettings({showSpinner:false}));
};

export const isLoggedIn = () => {
    return store.getState().user?.isLoggedIn;
};

export const loadNewMod = (mod, callback) => {

    import( 'src/' + mod  ).then(module => {
        callback(module)
    });
};

export const lowerCaseKeys = (data) => {
    if (Array.isArray(data)) {
        let length = data.length;
        let tempArray = [];

        while (length--) {
            tempArray[length] = lowerCaseKeys(data[length]);
        }

        return tempArray;
    } else if (typeof data === 'object' && data !== null) {

        let keyList = Object.keys(data);
        let amountOfKeys = keyList.length;
        let newObject = {};

        while (amountOfKeys--) {
            let key = keyList[amountOfKeys];
            let newKey = key.toLowerCase();
            newObject[newKey] = data[key];

            if (Array.isArray(newObject[newKey]) || typeof newObject[newKey] === 'object') {
                newObject[newKey] = lowerCaseKeys(newObject[newKey]);
            }
        }

        return newObject;

    } else {
        return data;
    }
};

export const makeAppTopMod = (mod) => {
    store.dispatch(updateAppSettings({appMainModuleName:mod}));
    loadNewMod(mod,(module)=>store.dispatch(updateDataStore({appMainModule:module.default})));
};

export const makeFormData = (data) => {
    let former = new FormData();
    for (let x in data) {
        if(data.hasOwnProperty(x)) {
            former.append(x, data[x]);
        }
    }
    return former;
};

export const obtainURLParam = (paramName) => {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === paramName) {
            return pair[1];
        }
    }
    return (false);
};
/*
export const obtainUser = () => {
    let newStore = store.getState();
    return newStore.user ? newStore.user.token : '';
};
*/
export const openModal = (componentName, title = '', footer = '', size='', triggerName="showDefaultModal") => {
    loadNewMod(
        componentName,
        (module) => store.dispatch(updateAppSettings({[triggerName]: true, modalModule: module.default, modalTitle: title,modalFooterText:footer,size:size}))
    )
};

export const parseInitials = (texter) => {
    let names = texter.split(" ");
    return (names.map((item) => {
        return item.substring(0, 1).toUpperCase()
    })).toString().replace(/,/g, '');
};

export const randNum = (min=1,max=99) => {
    return Math.floor(Math.random() * (max - min) ) + min;
};

export const resizeModal = (width,height) => {
    if(width){
        try{document.querySelector("#genericModal").style.width=width}catch(err){}
        try{document.querySelector("#genericModal").style.maxWidth=width}catch(err){}
        try{document.querySelector(".modal-content").style.width=width}catch(err){}
    }
    if(height){
        try{document.querySelector("#genericModal").style.height=height}catch(err){}
        try{document.querySelector("#genericModal").style.maxHeight=height}catch(err){}
        try{document.querySelector(".modal-content").style.height="100%"}catch(err){}
        try{document.querySelector(".modal-content").style.maxHeight="100%"}catch(err){}
        try{document.querySelector(".modal-body").style.height=height-175}catch(err){}
        try{document.querySelector(".modal-body").style.maxHeight=height-175}catch(err){}

    }
    try{document.querySelector("#genericModal").style.marginLeft="auto"}catch(err){}
    try{document.querySelector("#genericModal").style.marginRight="auto"}catch(err){}
};

export const setInnerHTMLBySelector = (selector,value) =>{
    try{document.querySelector(selector).innerHTML = value;}catch(err){console.dir(err)}
};

export const showSpinner = () => {
    store.dispatch(updateAppSettings({showSpinner:true}));
};

export const wsURL = (...args) => {
    let stateData=store.getState();
    //eslint-disable-next-line
    let site = args.length ? args[0] : self.location.hostname;

    return stateData?.appSettings?.wsURL?.hasOwnProperty(site) ? stateData?.appSettings.contentURL[site] : '';
};

const sizeArray = [
    {total:1,rows:1,lastRow:0,numberAcross:1},
    {total:2,rows:2,lastRow:1,numberAcross:1},
    {total:3,rows:2,lastRow:1,numberAcross:2},
    {total:4,rows:2,lastRow:2,numberAcross:2},
    {total:5,rows:2,lastRow:2,numberAcross:3},
    {total:6,rows:2,lastRow:3,numberAcross:3},
    {total:7,rows:3,lastRow:1,numberAcross:3},
    {total:8,rows:3,lastRow:2,numberAcross:3},
    {total:9,rows:3,lastRow:3,numberAcross:3},
    {total:10,rows:3,lastRow:4,numberAcross:3},
    {total:11,rows:3,lastRow:3,numberAcross:4},
    {total:12,rows:3,lastRow:4,numberAcross:4},

];

export const determineScreenOrientation = (width,height) => {
    let orientation = width > height ? "landscape" : "portrait";
    store.dispatch(updateAppSettings({screenOrientation:orientation}));
};
